@import '../../submodules/src/styles/aspect';
@import 'element/_mixins';
@import 'element/_icons-mixins';
@import 'element/_catalog-variables';

.icon-sort();
.icon-filter();
.icon-equalizer();
.icon-close();

#catalog-overlay {
  .base-overlay();
}

#catalog-controls-info {
  justify-content: center;
  padding: 1rem 0 1rem 1rem;

  @media (@min-screen-md) {
    justify-content: space-between;

    @media (@min-screen-md) {
      .catalog-desktop-sort-container:nth-child(2) {
        margin-left: auto;
      }
    }
  }
  @media (@max-screen-xs) {
    display: none !important;
  }
}

.filters-mobile-cr {
  .select-filter-mobile {
    border: 1px solid @input-color-border;
    width: 100%;
    font-size: 1.1rem;
    line-height: 3.2rem;
    border-bottom-width: 1px !important;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
    @media (max-width: 390px) {
      text-align: left;
    }
    @media (max-width: 350px) {
      font-size: 1rem;
      padding-left: 0.25rem;
      line-height: 3rem;
    }
  }

  select {
    &:not([type='button']),
    &:not([type='submit']),
    &:not([type='range']),
    &:not(.cr-radio),
    &:not(.cr-checkbox) {
      &:not(.search-mobile-input) {
        &.select-filter-mobile {
          border-bottom-width: 1px !important;
          line-height: 3.2rem;
          font-size: 1.1rem;
          @media (max-width: 350px) {
            font-size: 1rem;
            line-height: 3rem;
          }
        }
      }
    }
  }

  .cr-select select {
    padding-right: 1rem;
  }

  .cr-select::after {
    float: right;
    position: relative;
    margin-top: -1.5rem;
    top: 0;
    right: 0.25rem;
    @media (max-width: 350px) {
      right: 0;
      margin-top: -1.6rem;
    }
  }

  .dot-color {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
  }

  .badge-selected-item {
    background-color: @color-default-2;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }
}

.catalog-filter-group-btn {
  font-family: @roboto;
  font-size: 1rem;
  width: 13.563rem;
  align-items: center;
  justify-content: space-between;
  transition: background-color @transition-default, color @transition-default;
  background-color: white;
  border: 1px solid #848484;

  @media (max-width: 1220px) {
    width: 10.3rem;
  }

  @media (@max-screen-md) {
    width: 9rem;
  }

  &.active {
    background-color: transparent;
    color: @color-default-5;
    border: 1px solid var(--color-default-5);
    // background-color: @color-default-4;
    // color: white;
  }

  &:hover {
    background-color: @color-default-5;
    color: white;
  }
}

#catalog-mobile-toolbar {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  z-index: calc(@header-z-index - 1);
  width: 100vw;
  line-height: @catalog-mobile-toolbar-height;
  text-align: center;
  background-color: @color-default-1;
  pointer-events: initial;
}

#catalog-mobile-sort {
  list-style: none;
  padding-left: 0;
  position: fixed;
  left: 0;
  bottom: @catalog-mobile-toolbar-height;
  width: 100vw;
  transform: translateY(100%);
  background-color: white;
  text-align: center;
  box-shadow: @default-shadow;
  transition: transform @transition-default;
  pointer-events: initial;

  &.show {
    transform: none;
  }

  li {
    line-height: 3rem;
    border-bottom: 1px solid @color-default-2;

    &:first-child {
      text-transform: uppercase;
      background-color: @color-default-1;
    }
  }
}

#catalog-mobile-filter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: @header-z-index + 1;
  pointer-events: none;

  #catalog-mobile-filter-header,
  #catalog-mobile-filter-body {
    position: absolute;
    top: 0;
    right: -100%;
    width: 100vw;
    background-color: white;
    transition: right @drawer-transition-duration @transition-curve;
  }
}

.select-size {
  padding: 0.1rem 0.1rem;

  .icon-cr,
  .icon-cr:before {
    width: 1rem;
    height: 1rem;
  }
}

.catalog-delivery-ready {
  flex-direction: column;
  gap: 4px;

  :hover {
    .delivery-prompt {
      display: block;
    }
  }

  .delivery-ready-section {
    padding-left: 35px;

    flex-direction: column;
    gap: 8px;

    font-family: @font-proxima-nova;
    font-size: 14px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.28px;

    .filter-wrapper {
      gap: 8px;
    }

    .section-item-wrapper {
      display: flex;
      gap: 8px;
    }

    .section-options {
      display: flex;
      gap: 16px;
    }
  }
}

.delivery-ready-section.mobile {
  .section-text {
    flex-direction: column;
    gap: 8px;
  }
}

.filter-wrapper-mobile {
  flex-wrap: wrap;
  gap: 8px;
}

.filter-item {
  :hover {
    color: @color-primary-5;
  }

  &.mobile {
    border: 1px solid @color-default-5;
    flex: 1;

    &.model:nth-child(3n) {
      flex-basis: 100%;
    }

    &.size {
      flex-basis: 20%;
    }

    .item-content {
      span {
        padding: 15px 0;
        width: 100%;

        display: flex;
        justify-content: center;
      }

      :hover {
        background-color: @color-default-5;
        color: @color-default-1;
      }
    }
  }
}

.delivery-prompt {
  display: none;
  font: @roboto;
}

.catalog-delivery-ready-text {
  font-weight: 700;
  // line-height: 0.875rem;
  line-height: 1.5rem;

  font-size: 1rem;
  letter-spacing: 0.02em;
}

/** ---- QUICK FILTERS ---- **/

.catalog-quick-filters-list {
  justify-content: center;
  margin: 0 -0.5rem -0.5rem;

  .catalog-quick-filter {
    margin: 0 0.5rem 0.5rem;
  }
}

.catalog-quick-filter {
  background-color: @color-default-1;
  transition: color @transition-default, background-color @transition-default;

  &:hover {
    background-color: @color-primary-3;
    color: @color-white;
    text-decoration: none;

    svg {
      fill: @color-white;
    }
  }

  svg {
    display: none;
    height: 2rem;
    transition: fill @transition-default;
  }

  &.selected svg {
    fill: white;
  }

  @media (@min-screen-sm) {
    svg {
      display: block;
    }
  }
}

.desktop-filters-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  @media (@min-screen-lg) {
    justify-content: space-between !important;
  }
}

.catalog-controls {
  @media (@max-screen-xs) {
    display: block !important;
  }
}

.toggle-delivery-ready {
  .toggle-delivery-wrapper {
    flex-direction: column;
  }

  .cr-switch + label::before {
    @media (@max-screen-xs) {
      width: 4rem;
      border-radius: 1.5rem;
      height: 2rem;
    }
  }

  .cr-switch + label::after {
    @media (@max-screen-xs) {
      width: 1.9rem;
      height: 1.9rem;
      left: 1rem;
      border-radius: 1.45rem;
    }
  }

  .cr-switch + label {
    @media (@max-screen-xs) {
      font-size: 1rem;
      padding-left: 5.5rem;
    }
  }

  .cr-switch:checked + label::after,
  .cr-switch:active + label::after {
    @media (@max-screen-xs) {
      margin-left: 1.9rem;
    }
  }
}
